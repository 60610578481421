import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';

import styles from './team.module.css';

const Person = ({ name, title, image, children }) => (
  <div className={styles.person}>
    <div className={styles.header}>
      <Img className={styles.image} fixed={image} />
      <div>
        <h3>{name}</h3>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
    <p>
      {children}
    </p>
  </div>
);

Person.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  children: PropTypes.node,
};

const TeamPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Team update">
        <p>
          We asked our team what working at Mount Mary means to them.
        </p>
        <div className={styles.row}>
          <Person
            name="Sam"
            title="Head Winemaker"
            image={data.samImage.childImageSharp.fixed}
          >
            &quot;I have lived at Mount Mary most of my life and developed a strong
            connection with this site. I’m extremely passionate about the wine
            style established by my grandfather and father in the 1970’s. I am
            committed to the continual improvement in both vineyard and winery
            operations at Mount Mary into the future.&quot;
          </Person>
          <Person
            name="Claire"
            title="Business Manager"
            image={data.claireImage.childImageSharp.fixed}
          >
            &quot;To be able to execute our shared family vision in our everyday
            roles is certainly a privilege and the greatest reward. We are
            motivated to continue to adapt and evolve to the changing
            environment, ensuring Mount Mary is producing wines of the highest
            quality from our unique site for generations to come.&quot;
          </Person>
        </div>
        <div className={styles.row}>
          <Person
            name="Liz"
            title="Office Manager"
            image={data.lizImage.childImageSharp.fixed}
          >
            &quot;Coming to work each day is sheer joy. The varied nature of my role
            is both stimulating and rewarding as the Middleton Family and the
            MM Team, always provide a friendly, skilful, caring and enjoyable
            work environment. It is a great place to be!&quot;
          </Person>
          <Person
            name="Chris"
            title="Vineyard Supervisor"
            image={data.chrisImage.childImageSharp.fixed}
          >
            &quot;I feel passionate about working with such a special site, as we
            strive toward the next one percent of fruit and wine improvement at
            Mount Mary.&quot;
          </Person>
        </div>
        <div className={styles.row}>
          <Person
            name="Marco"
            title="Vineyard Assistant"
            image={data.marcoImage.childImageSharp.fixed}
          >
            &quot;It’s great to be part of the small team that produces exceptional
            grapes that go into making the unique Mount Mary wines.  And the views
            at Mount Mary are amazing.&quot;
          </Person>
          <Person
            name="Alexei"
            title="Vineyard Assistant"
            image={data.alexeiImage.childImageSharp.fixed}
          >
            &quot;I’m passionate about learning how to grow premium wine, right from
            the beginning in the vineyard... and to be as close as possible to
            the source of Triolet! Particularly the old bottles.&quot;
          </Person>
        </div>
        <div className={styles.row}>
          <Person
            name="Oliver"
            title="Grounds Assistant"
            image={data.oliverImage.childImageSharp.fixed}
          >
            &quot;It’s a great place to work and the people I work with makes it even
            better.&quot;
          </Person>
          <Person
            name="FAREWELL... Kaspar"
            title="Assistant Winemaker"
            image={data.kasparImage.childImageSharp.fixed}
          >
            Kaspar left Mount Mary in December 2018 to continue his professional
            journey at Rochford Wines. We thank him for his significant
            contribution to the Mount Mary team and wish him well in this next
            phase of his career.
          </Person>
        </div>
      </Section>
      <Pagination
        forwardLink="/vineyard"
        backLink="/chateau-margaux"
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    claireImage: PropTypes.object,
    samImage: PropTypes.object,
    lizImage: PropTypes.object,
    chrisImage: PropTypes.object,
    marcoImage: PropTypes.object,
    alexeiImage: PropTypes.object,
    oliverImage: PropTypes.object,
    kasparImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    samImage: file(relativePath: { eq: "Sam.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    claireImage: file(relativePath: { eq: "Claire.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lizImage: file(relativePath: { eq: "Liz.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chrisImage: file(relativePath: { eq: "Chris.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    marcoImage: file(relativePath: { eq: "Marco.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    alexeiImage: file(relativePath: { eq: "Alexei.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    oliverImage: file(relativePath: { eq: "Oliver.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kasparImage: file(relativePath: { eq: "Kaspar.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default TeamPage;
